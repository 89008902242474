import axios from 'axios';

var controllerName = "Nav";

class NavServices {
    GetNavMenu() {
        let user = JSON.parse(localStorage.getItem('user'));
        return axios.post(controllerName + '/Get',{token:user.token}).then((snapshot) => cleanEmpty(snapshot.data));
    }

    GetAllData(headers) {
        return axios.get(controllerName + '/GetAll', {headers: headers} ).then((snapshot) => snapshot.data);
    }

}

const cleanEmpty = obj => {
    if (Array.isArray(obj)) {
        return obj
            .map(v => (v && typeof v === 'object') ? cleanEmpty(v) : v)
            .filter(v => !(v == null));
    } else {
        return Object.entries(obj)
            .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
            .reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
    }
}
export default new NavServices();