<template>
    <div class="d-flex">
        <div class="header-btn-lg pr-0">
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                        <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-lg" variant="link" right>
                            <span slot="button-content">
                                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <img width="42" height="42" class="rounded-circle" :src="currentUser?.profilePicture" alt="">
                                </div>
                            </span>
                            <router-link to="/profile" class="dropdown-item" style="text-decoration: none;">{{ this.$i18n.t('header.profile') }}</router-link>
                            <router-link to="/accountSettings" class="dropdown-item" style="text-decoration: none;">{{ this.$i18n.t('header.accountSettings') }}</router-link>
                            <div tabindex="-1" class="dropdown-divider"></div>
                            <button type="button" tabindex="0" class="dropdown-item" @click="logout" style="cursor:pointer;">{{this.$i18n.t('header.logout')}}</button>
                        </b-dropdown>
                    </div>
                    <div class="widget-content-left  ml-3 header-user-info">
                        <div class="widget-heading">{{ currentUser?.name }}</div>
                        <div class="widget-subheading">{{ currentUser?.email }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import CookieHelper from '../../../helper/CookieHelper';
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faAngleDown,
        faCalendarAlt,
        faTrashAlt,
        faCheck,
        faFileAlt,
        faCloudDownloadAlt,
        faFileExcel,
        faFilePdf,
        faFileArchive,
        faEllipsisH,
    } from '@fortawesome/free-solid-svg-icons'
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faAngleDown,
        faCalendarAlt,
        faTrashAlt,
        faCheck,
        faFileAlt,
        faCloudDownloadAlt,
        faFileExcel,
        faFilePdf,
        faFileArchive,
        faEllipsisH,
    );

    export default {
        components: {
            //'font-awesome-icon': FontAwesomeIcon,
        },
        data: () => ({
            currentUser: JSON.parse(localStorage.getItem('user'))
        }),

        methods: {
            logout() {
                this.$store.dispatch("auth/logout").then(
                () => {
                    if(CookieHelper.getCookie("checkbox") == true && CookieHelper.getCookie("username") != null, CookieHelper.getCookie("password") != null)
                    {
                        this.username = CookieHelper.getCookie('username');
                        this.password = CookieHelper.getCookie('password');
                        this.chkRememberMe = CookieHelper.getCookie('checkbox');
                    }

                    this.$toastr.i(this.$i18n.t('toaster.logoutSuccess'), this.$i18n.t('toaster.transactionSuccess'));
                    this.$router.push('/login');
                },
                (error) => {
                    this.$toastr.e(this.$i18n.t('toaster.somethingWentWrong'), this.$i18n.t('toaster.unknownError'));
                })
            }
        }
    }
</script>