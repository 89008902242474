<template>
    <div class="d-flex">
        <div class="header-btn-lg pr-0">
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                        <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-lg" variant="link" right>
                            <span slot="button-content">
                                <div class="icon-wrapper icon-wrapper-alt rounded-circle" >
                                    <country-flag id="localeChanger" :country='this.lang ?? "tr"' size='normal' style="margin:0px"/> 
                                </div>
                            </span>
                            <div class="dropdown-item" v-for="(lang, i) in langs" :key="`lang-${i}`" style="cursor: pointer;display: flex;align-items: center;" @click="ChangeLang(lang)">
                                <country-flag :country='lang' size='normal' style="margin:0px"/> {{ $t('locale.' + lang) }}
                            </div>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import CountryFlag from 'vue-country-flag'

export default {
    name: 'SelectLocale',
    components: {
        'country-flag':CountryFlag
    },
    data() {
        return {
            langs: ['gb', 'tr'],
            lang: localStorage.getItem("lang")
        }
    },
    methods: {
        ChangeLang(event) {
            localStorage.setItem("lang",event);
            location.reload();
        }
    },
    mounted(){
            this.lang = localStorage.getItem("lang") == null ? Vue.prototype.$defaultLanguage : localStorage.getItem("lang");
    }
}
</script>