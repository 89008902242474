import Vue from 'vue'
import router from './router'
import store from "./store";
import BootstrapVue from "bootstrap-vue"
import { BTooltip } from 'bootstrap-vue'
import App from './App'
import '@/axios'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueToastr from "vue-toastr";

import Default from './Layout/Wrappers/baseLayout.vue';
import Login from './Layout/Wrappers/loginLayout.vue';

import Vuetify from 'vuetify'

Vue.config.productionTip = false;
Vue.prototype.$sitekey = "6LcUfegkAAAAALmZv-vruwk1BCgoN4Ly0PUzruuI";
Vue.prototype.$secretkey = "6LcUfegkAAAAAEqijr1D5J5LRDI5WiDzf-kyrhqJ";
Vue.prototype.$version = "v1.0.0";
Vue.prototype.$supportURL = "https://www.verimix.com.tr";
Vue.prototype.$headerTheme = ["bg-midnight-bloom", "text-lighter"]; //src/assets/utils/_backgrounds.scss
Vue.prototype.$sidebarTheme = ["bg-midnight-bloom", "text-lighter"]; //src/assets/utils/_backgrounds.scss
Vue.prototype.$appThemeFlags = {
    fixedheader: true,
    fixedsidebar: true,
    fixedfooter: true,
    bodytabsline: true
};

Vue.use(VueToastr);
Vue.use(BootstrapVue);
Vue.use(Vuetify);

import i18n from './i18n'

Vue.component('default-layout', Default);
Vue.component('login-layout', Login);

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    template: '<App/>',
    components: { App, BTooltip },
    mounted() {
        this.$toastr.defaultPosition = "toast-top-right";
    }
});
