<template>
    <div class="app-container">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'app',
    components: {
    },
    methods: {

    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/").catch(() => {});
        }
    }
}
</script>