<template>
    <div id="app">
        <component :is="layout" v-cloak>
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </component>
    </div>
</template>

<script>

const default_layout = "default";

export default {
    computed: {
        layout() {
            return (this.$route.meta.layout || default_layout) + '-layout';
        }
    }
}
</script>

<style lang="scss">
    @import "assets/base.scss";
</style>
