import axios from 'axios';

var controllerName = "Auth";
class AuthService {
    login(params) {
        return axios
            .post(controllerName + '/Login',
                params
            )
            .then(snapShot => {
                if (snapShot != null) {
                    localStorage.setItem('user', JSON.stringify(snapShot.data));
                }

                return snapShot;
            }).catch((error) => {
                console.log(error);
                return error;
            });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('navMenu');
    }

    forgotPassword(params) {
        return axios.post(controllerName + '/ForgotPassword',params).then((snapshot) => snapshot.data);
    }

    resetPassword(params) {
        return axios.post(controllerName + '/ResetPassword/',params).then((snapshot) => snapshot.data);
    }

    ActivateAccount(params) {
        return axios.put(controllerName + '/ActivateAccount/',params).then((snapshot) => snapshot.data);
    }
}

export default new AuthService();