import Vue from 'vue'
import Router from 'vue-router'
import PermissionService from "../services/permission.service";
import NavService from "../services/nav.service";
import VueToastr from "vue-toastr";

Vue.use(VueToastr);
Vue.use(Router);

const router = new Router({
    history: true,
    mode: "history",
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: () => import('../Pages/Dashboards/Analytics.vue'),
        },
        {
            path: '/login/',
            name: 'login',
            meta: { layout: 'login' },
            component: () => import('../Pages/Global/LoginPages/Login.vue'),
        },
        {
            path: '/forgot-password/',
            name: 'forgot-password',
            meta: { layout: 'login' },
            component: () => import('../Pages/Global/LoginPages/ForgotPassword.vue'),
        },
        {
            path: '/reset-password/',
            name: 'reset-password',
            meta: { layout: 'login' },
            component: () => import('../Pages/Global/LoginPages/ResetPassword.vue'),
        },
        {
            path: '/account-activation/',
            name: 'account-activation',
            meta: { layout: 'login' },
            component: () => import('../Pages/Global/LoginPages/ActivationPage.vue'),
        },
        {
            path: '/users/',
            name: 'users',
            component: () => import('../Pages/Global/RoleBasedManagement/Users.vue'),
        },
        {
            path: '/roles/',
            name: 'roles',
            component: () => import('../Pages/Global/RoleBasedManagement/Roles.vue'),
        },
        {
            path: '/permissions/',
            name: 'permissions',
            component: () => import('../Pages/Global/RoleBasedManagement/Permissions.vue'),
        },
        {
            path: '/mailserver/',
            name: 'mailserver',
            component: () => import('../Pages/Global/ApplicationSettings/MailServer.vue'),
        },
        {
            path: '/connections/',
            name: 'connections',
            component: () => import('../Pages/Global/ApplicationSettings/Connections.vue'),
        },
        {
            path: '/loginhistory/',
            name: 'loginhistory',
            component: () => import('../Pages/Global/Logs/LoginHistory.vue'),
        },
        {
            path: '/exception/',
            name: 'exception',
            component: () => import('../Pages/Global/Logs/Exception.vue'),
        },
        {
            path: '/accountSettings/',
            name: 'accountSettings',
            component: () => import('../Pages/Global/AccountSettings/AccountSettings.vue'),
        },
        {
            path: '/profile/',
            name: 'profile',
            component: () => import('../Pages/Global/AccountSettings/ProfilePage.vue'),
        },
        {
            path: '/changePassword/',
            name: 'changePassword',
            component: () => import('../Pages/Global/AccountSettings/ChangePassword.vue'),
        },
        {
            path: '/orderListPage/',
            name: 'orderListPage',
            component: () => import('../Pages/App/OrderListPage.vue'),
        },
        {
            path: '/orderCreatePage/',
            name: 'orderCreatePage',
            component: () => import('../Pages/App/OrderCreatePage.vue'),
        },
        {
            path: '/accountStatement/',
            name: 'accountStatement',
            component: () => import('../Pages/App/CurrentAccountStatement.vue'),
        },
        {
            path: '/parameterPage/',
            name: 'parameterPage',
            component: () => import('../Pages/App/ParameterPage.vue'),
        },
    ]
})

router.beforeEach((to, from, next) => {
    try {
        const publicPages = ['/login', '/forgot-password', '/reset-password', '/account-activation',];
        const authRequired = !publicPages.includes(to.path);
        const loggedIn = localStorage.getItem('user');
        
        if(!authRequired){
            next();
        } 
        else if (authRequired && loggedIn == null) {
            next('/login');
        }
        else if(authRequired && loggedIn != null) {
            NavService.GetNavMenu(
            ).then((snapshot) => {
                if(JSON.stringify(snapshot) != localStorage.getItem('navMenu'))
                {
                    localStorage.setItem("navMenu", JSON.stringify(snapshot));
                    window.location.replace(to.path);
                }
            }).catch((err) => {
                console.log(err.message);
            });
            
            PermissionService.CheckPermission(
                {route: to.path}
            ).then((snapshot) => {
                if(snapshot)
                    next();
                else
                    this.$toastr.e('You do not have a permission to see this page!', 'Access Denied');
            }).catch((err) => {
                console.log(err.message);
            });   
        }
        else{
            next('/login');
        }
    } catch (error) {
        return null;
    }
});

export default router;
