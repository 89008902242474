import Vue from 'vue'
import VueI18n from 'vue-i18n';
import tr from "./i18n/locales/tr.json"
import gb from "./i18n/locales/gb.json"

Vue.use(VueI18n);
const messages = {
    tr: tr,
    gb: gb
};

const i18n = new VueI18n({
    lazy: true,
    locale: localStorage.getItem("lang") || "tr",
    fallbackLocale: "tr",
    messages
});

export default i18n;