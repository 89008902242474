<template>
    <div>
        <div class="app-container app-theme-white mb-2" :class="appThemeFlags">
            <transition name="fade" mode="out-in" appear>
                <HeaderMT />
            </transition>
            <transition name="fade" mode="out-in" appear>
                <Sidebar/>
            </transition>
            <div class="app-main__outer">
                <div class="app-main__inner">
                    <slot></slot>
                </div>
                <transition name="fade" mode="out-in" appear>
                    <FooterMT />
                </transition>
            </div>
            <!-- Modal Component -->
            <b-modal id="modal1" title="Bootstrap-Vue">
                <p class="my-4">Hello from modal!</p>
            </b-modal>
            <b-modal id="modallg" size="lg" title="Large Modal">
                Hello Modal!
            </b-modal>
            <b-modal id="modalsm" size="sm" title="Small Modal">
                Hello Modal!
            </b-modal>
        </div>
        <div id="pleaseWaitIndicator" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4); text-align: center; display: none; z-index: 9999; padding: 21%;">
            <center>
                <div style="width: 200px; height: 87px; overflow: hidden; border-radius:5px">
                    <img style="margin: -45px 0 0 -50px;" src="../../assets/images/pleasewait.gif" />
                </div>
            </center>
        </div>
    </div>
</template>

<script>
import HeaderMT from "../Components/HeaderMT";
import Sidebar from "../Components/Sidebar";
import FooterMT from "../Components/FooterMT";
//import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCog,
} from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from "vuex";
// import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(
    faCog,
);
export default {
    name: 'app',
    components: {
        HeaderMT,
        Sidebar,
        FooterMT,
    },
    data() {
        return {
            appThemeFlags: this.getAppThemeFlags(this.$appThemeFlags),
        }
    },
    methods: {
        getAppThemeFlags(appThemeFlags) {
            var activeFlags = [];
            activeFlags.push(appThemeFlags.fixedheader ? "fixed-header" : "");
            activeFlags.push(appThemeFlags.fixedsidebar ? "fixed-sidebar" : "");
            activeFlags.push(appThemeFlags.fixedfooter ? "fixed-footer" : "");
            activeFlags.push(appThemeFlags.bodytabsline ? "body-tabs-line" : "body-tabs-shadow");
            return activeFlags;
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        //  
    }
}
</script>
