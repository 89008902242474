import axios from 'axios'
import authHeader from './services/auth-header';
import i18n from './i18n'

window.axios = axios;
axios.interceptors.request.use((config) => {
        if(document.getElementById("pleaseWaitIndicator")!=null)
            document.getElementById("pleaseWaitIndicator").style.display="block";
        return config;
    }, (error) => {
        if(document.getElementById("pleaseWaitIndicator")!=null)
            document.getElementById("pleaseWaitIndicator").style.display="none";
        return Promise.reject(error);
    });
axios.interceptors.response.use(
    function (response) {
        if(document.getElementById("pleaseWaitIndicator")!=null)
            document.getElementById("pleaseWaitIndicator").style.display="none";
        var data = response.data;
        //NavMenu Localization Interceptor
        if (response.request.responseURL.includes("/Nav/Get") && !response.request.responseURL.includes("GetAll") ) {
            data.forEach(function (item) {
                Object.keys(item).forEach(function (key) {
                    if (key == "title")
                        item["title"] = i18n.t('navigation.' + item["title"]);
                });
                if (item["child"] != null) {
                    item["child"].forEach(function (entry) {
                        entry["title"] = i18n.t('navigation.' + entry["title"]);
                    });
                }
            });
        }
        if (response.request.responseURL.includes("/Nav/GetAll")) {
            data.items.forEach(function (item) {
                Object.keys(item).forEach(function (key) {
                    if (key == "title")
                    {
                        item["routeTitle"] = i18n.t('navigation.' + item["title"]);
                        if(item["title"] != "dashboard")
                            item["title"] = item["href"];
                        else
                            item["title"] = "/";
                    }
                });
            });
        }

        //Permission Localization Interceptor
        if (response.request.responseURL.includes("/Permission/GetAll") || response.request.responseURL.includes("/Permission/GetFiltered")) {
            data.items.forEach(function (item) {
                Object.keys(item).forEach(function (key) {
                    if (key == "routeTitle")
                        item["routeTitle"] = i18n.t('navigation.' + item["routeTitle"]);
                });
            });
        }

        if (data.items == undefined)
        {
            if(Array.isArray(data))
                data = manipulateListData(data)
            else
                data = manipulateData(data);
        }
        else
        {
            if(Array.isArray(data.items))
                data.items = manipulateListData(data.items);
            else
                data.items = manipulateData(data.items);
        }
        return response;
    },
    function (error) {
        if(document.getElementById("pleaseWaitIndicator")!=null)
            document.getElementById("pleaseWaitIndicator").style.display="none";
        if (error.response.status == 401)
            location.href = "/login";
    }
);
axios.defaults.withCredentials = false;

//Ip sorgulama
var externalIP = await fetch('https://api.ipify.org/').then((response) => response.text());
axios.defaults.baseURL = externalIP == "212.154.70.154" ? "http://192.168.1.8:4545/api/v1/" :"https://api.zorluambalaj.com.tr:5001/api/v1/"; //Canli Ortamp
//Ip sorgulama

//axios.defaults.baseURL = "https://192.168.1.8:5000/api/v1/";  //Musteri Ortami
// axios.defaults.baseURL = "https://localhost:5000/api/v1/"; //Development Ortami
if (authHeader().Authorization != null || authHeader().Authorization != undefined)
    axios.defaults.headers.common['Authorization'] = authHeader().Authorization;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = "application/json";
axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || "tr";
axios.defaults.headers.common['Request-From'] = window.location.href;
axios.defaults.headers.common['App-Id'] = 1;

function manipulateListData(data){
    data.forEach(function (item) {
        var keys = [];
        //DateTime Convert Interceptor
        Object.keys(item).forEach(function (key) {
            if (/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/.test(item[key]))
                keys.push(key);
        });
        keys.forEach((key) => {
            if (key == undefined)
                return data;
            var date = item[key].split('T')[0];
            var time = item[key].split('T')[1];
            item[key + "_shortdate"] = date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0];
            item[key + "_longdate"] = date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0] + " " + time.split(":")[0] + ":" + time.split(":")[1] + ":" + time.split(":")[2].slice(0, 2);
            item[key + "_iso"] = date;
            item[key + "_longiso"] = date + " " + time.split(":")[0] + ":" + time.split(":")[1] + ":" + time.split(":")[2].slice(0, 2);
        });
        //isActive Convert Interceptor
        Object.keys(item).forEach(function (key) {
            if (key == "isActive")
                item["isActive_label"] = item["isActive"] == true ? i18n.t('status.active') : i18n.t('status.deactive');
        });
    });
    return data;
}

function manipulateData(data){
        var keys = [];
        //DateTime Convert Interceptor
        Object.keys(data).forEach(function (key) {
            if (/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/.test(data[key]))
                keys.push(key);
        });
        keys.forEach((key) => {
            if (key == undefined)
                return data;
            var date = data[key].split('T')[0];
            var time = data[key].split('T')[1];
            data[key + "_shortdate"] = date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0];
            data[key + "_longdate"] = date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0] + " " + time.split(":")[0] + ":" + time.split(":")[1] + ":" + time.split(":")[2].slice(0, 2);
            data[key + "_iso"] = date;
            data[key + "_longiso"] = date + " " + time.split(":")[0] + ":" + time.split(":")[1] + ":" + time.split(":")[2].slice(0, 2);
        });
        //isActive Convert Interceptor
        Object.keys(data).forEach(function (key) {
            if (key == "isActive")
                data["isActive_label"] = data["isActive"] == true ? i18n.t('status.active') : i18n.t('status.deactive');
        });
    return data;
}