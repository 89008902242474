import axios from 'axios';

var controllerName = "Permission";

class PermissionService {
    Get(id) {
        return axios.get(controllerName + '/Get/'+ id).then((snapshot) => snapshot.data);
    }
    
    GetAllData(headers) {
        return axios.get(controllerName + '/GetAll', {headers: headers} ).then((snapshot) => snapshot.data);
    }

    GetFilteredData(params,headers) {
        return axios.post(controllerName + '/GetFiltered', params, {headers: headers}).then((snapshot) => snapshot.data);
    }

    Save(params) {
        return axios.post(controllerName + '/Save',params).then((snapshot) => snapshot.data);
    }
    
    Update(params) {
        return axios.put(controllerName + '/Update/',params).then((snapshot) => snapshot.data);
    }
    
    Delete(id,forceDelete) {
        return axios.delete(controllerName + '/Delete/'+id+'/'+forceDelete).then((snapshot) => snapshot.data);
    }

    CheckPermission(params){
        let user = JSON.parse(localStorage.getItem('user'));
        var reqParams= {
            route: params.route,
            token: user.token
        };
        return axios.post(controllerName + '/CheckPermission', reqParams).then((snapshot)=> snapshot);
    }
}
export default new PermissionService();